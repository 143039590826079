body {
  padding-top: 20px;
}

.navbar .navbar-brand {
  img {
    display: inline-block;
    max-height: 20px;
  }
}
