.site-footer {
  margin: 80px 0;

  h3 {
    font-size: 18px;
    font-weight: 200;
  }

  .footer-links {
    > a, > span {
      color: #777;
      margin-right: 16px;
      font-size: 12px;
      font-weight: 400;
    }
  }
}
