img {
  max-width: 100%;
}

.project {
  h1 {
    font-weight: 800;
    font-size: 20px;
    margin-bottom: 30px;
  }
  h2 {
    font-weight: 400;
    font-size: 14px;
  }

  h1 + h2 {
    margin-top: -22px;
    margin-bottom: 30px;
  }
}

.carousel {
  a {
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  }

  .item img {
    max-height: 600px;
    min-width: auto;
    margin: auto;
  }

}

@media only screen and (min-width : 480px) {
  .section-grid {
    padding: 0 100px;
  }
}

.section-grid a {
  display: block;
  margin-bottom: 30px;
}
